import React, { useEffect, useState, useContext, useRef } from 'react';
import firebase, { storage, database } from './firebase.js';
import dayjs from 'dayjs';
import { useLocation } from 'react-router-dom';

import { Link } from 'react-router-dom';
import Upload from './fileUpload';
import ViewMP from './ViewMP';
import ViewAggregate from './ViewAggregate'
import Admin from './admin'
import Anbun from './anbun'
import SaisanKamokuManagement from './SaisanKamokuManagement';
import Achievement from './fileUploadAchievement';
import Dashboard from './Dashboard';
import YojitsuSaisan from './YojitsuSaisan';
import ViewYotei from './ViewYotei';
import ViewMikomi from './ViewMikomi';
import ViewAchChange from './ViewAchChange';
import AnbunView from './AnbunView';
import Finalize from './Finalize';
import GoogleDirectorySync from './GoogleDirectorySync';
import TeamcodeManagement from './TeamcodeManagement';
import FormatManagement from './FormatManagement';
import AccountManagement from './AccountManagement';
import NewYearCreation from './NewYearCreation';
import ForcedChange from './ForcedChange';
import { useAuthContext } from './Auth';
import { NoticeRead, NoticeDelete } from './database/Notice';
import { Store } from './store';
import ReadTeams from './database/ReadTeams';
import { TemplateDownloadMP, TemplateDownloadMikomi, TemplateDownloadYotei } from './functional/TemplateDownloadTrigger';

import { TransitionGroup } from 'react-transition-group';
import { makeStyles } from "@mui/styles";
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import {
    Drawer,
    List,
    CssBaseline,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Badge,
    AppBar,
    Box,
    Toolbar,
    IconButton,Typography,
    Menu,
    Container,
    Avatar,
    Tooltip,
    MenuItem,
    Popover,
    Collapse,
    Button,
    TextField,
    FormControl,
    InputLabel,
    Select,
    OutlinedInput,
    SelectChangeEvent,
    Slide,
} from '@mui/material';
import { LoadingButton, loadingButtonClasses } from '@mui/lab';

import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircularProgress, {
    CircularProgressProps,
} from '@mui/material/CircularProgress';
import {
    ChevronLeft,
    ChevronRight,
    Notifications,
    InsightsOutlined,
    FileUploadRounded,
    FileUploadOutlined,
    SettingsOutlined,
    EditRounded,
    CalculateOutlined,
    Storage,
    GroupsOutlined,
    LockOutlined,
    SearchOutlined,
    ViewListOutlined,
    Percent,
    Google,
    FormatListNumberedRounded,
    CheckCircleOutline,
    Error,
    CloseRounded,
    Verified,
    VerifiedOutlined,
    ExpandMoreRounded,
    HelpRounded,
    Check,
    WarningRounded,
    CreateNewFolderOutlined,
    LogoutOutlined,
    FileDownloadOutlined,
    PublishedWithChangesOutlined,
    InfoRounded,
} from '@mui/icons-material';
import MenuIcon from '@mui/icons-material/Menu'
// import NoTasks from './assets/NoTasks.png'
import NoTasks from './assets/notask.png'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { GlobalUploader } from './functional/GlobalUploader';

const headerHeight:number = 48;
const drawerWidth = 240;

const navMenu:any = {
    'dashboard' : {
        'open'  : false,
        'dashboard' : {
            name: 'ダッシュボード',
            path: '/v/dashboard',
            component: Dashboard
        },
    },
    'viewer' : {
        'items' : [ 'viewMP', 'viewTarget', 'viewExpect', 'viewActual', 'viewAggregate', 'viewAverage', ],
        'open'  : false,
        'name': "採算表照会",
        'viewMP' : {
            name: 'MP照会',
            path: '/v/mp',
            component: ViewMP,
        },
        'viewTarget' : {
            name: '予定照会',
            path: '/v/target',
            component: ViewYotei
        },
        'viewExpect' : {
            name: '見込／概算照会',
            path: '/v/expect',
            component: ViewMikomi
        },
        'viewActual' : {
            name: '予実採算表',
            path: '/v/actual',
            component: YojitsuSaisan
        },
        'viewAggregate' : {
            name: '部門集計表',
            path: '/v/aggregate',
            component: ViewAggregate
        },
        'viewAverage' : {
            name: '実績推移表',
            path: '/v/average',
            component: ViewAchChange
        },
        'viewAnbunDetail' : {
            name: '按分結果',
            path: '/v/ditribution',
            component: AnbunView
        },
    },
    'upload' : {
        'open': false,
        'upload' : {
            name: 'アップロード',
            path: '/o/upload',
            component: Upload
        }
    },
    'actual' : {
        'open' : false,
        'limit': false,
        'actual' : {
            name: '実績管理',
            path: '/o/actual',
            component: Achievement
        }
    },
    'distribution' : {
        'open' : false,
        'limit': false,
        'distribution' : {
            name: '按分',
            path: '/o/distribution',
            component: Anbun
        }
    },
    'master' : {
        'items' : [ 'teams', 'formats', 'profits', 'accounts', 'divides'],
        'name': "マスタ管理",
        'open'  : false,
        'teams' : {
            name: 'チームマスタ',
            path: '/m/teams',
            component: TeamcodeManagement
        },
        'formats' : {
            name: '採算表フォーマット',
            path: '/m/format',
            component: FormatManagement
        },
        'profits' : {
            name: '採算科目マスタ',
            path: '/m/profit',
            component: SaisanKamokuManagement
        },
        'accounts' : {
            name: '勘定科目マスタ',
            path: '/m/account',
            component: AccountManagement
        },
        'divides' : {
            name: '按分マスタ',
            path: '/m/profit',
            component: SaisanKamokuManagement
        },
    },
    'profitManagement' : {
        'items' : [ 'force', 'finalize', 'project'],
        'open'  : false,
        'name': "採算表管理",
        'limit': false,
        'force' : {
            name: '提出状況管理',
            path: '/o/force',
            component: ForcedChange
        },
        'finalize' : {
            name: '確定処理管理',
            path: '/o/finalize',
            component: Finalize
        },
        'project' : {
            name: '新年度作成',
            path: '/o/project',
            component: NewYearCreation
        },
    },
    'for developer' : {
        'open'  : false,
        'limit': false,
        'for developer' : {
            name: 'for developer',
            path: '/o/developer',
            component: Admin
        },
    },
    'GoogleDirectorySync' : {
        'open'  : false,
        'limit': false,
        'GoogleDirectorySync' : {
            name: 'GoogleDirectorySync',
            path: '/o/sync',
            component: GoogleDirectorySync
        },
    },
}

const useStyles = makeStyles((theme: Theme) => ({
    appbar: {
        minHeight: headerHeight,
        maxHeight: headerHeight,
    },
    toolbar: {
        minHeight: headerHeight,
        maxHeight: headerHeight,
    },
    accordion: {
        overflow: 'hidden',
        color: 'red',
        display: 'block',
        height: 0,
    },
    noneAccordion: {
        width: 'calc(100% - 8px)',
        marginLeft: 8,
    },
    closeMenu: {
        height: 'auto',
        width:  'auto',
        fontSize: 8,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: -12,
        marginRight: 2,
        opacity: '87%',
    },
    openMenu: {
        height: 'auto',
        width:  'auto',
        fontSize: 8,
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: -12,
        marginRight: 2,
        opacity: '87%',
        transform: 'rotate(90deg)',
        // transition: theme.transitions.create('all', {
        //     duration: theme.transitions.duration.shortest,
        // })
    },
}));

const NotificationsListItem = styled(ListItem)({
    "&:hover": {
        backgroundColor:"#fafafa",
    },
    "&:hover> .notifications-deletebutton":{
        display:"inline-block",
        position:"absolute",
    },
    ".notifications-deletebutton":{
        display:"none",
        position:"absolute",
        right:0,
    },
})

const IconStyle = {
    fontSize: 24
};

// navigation drawer 
const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    overflowX: 'hidden',
    zIndex: 99,
    border: 0,
});
const closedMixin = (theme: any): CSSObject => ({
    width: `calc(${theme.spacing(7)} + 1px)`,
    overflowX: 'hidden',
    zIndex: 99,
    border: 0,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});
const DrawerHeader = styled('div')(({ theme }:any) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));
const StyledDrawer = styled(Drawer, { shouldForwardProp: (prop:any) => prop !== 'open' })(({ theme, open }:any) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        border: 0,
        backgroundColor: "red",
        
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
}

function a11yProps(index: number) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const ResponsiveAppBar = () => {
    const { permission } = useAuthContext();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [limitMenu, setLimitMenu] = useState<any>({ ...navMenu })

    useEffect(() => {
        let limitedMenu = {}
        if ( permission.permission === "user" ) {
            Object.keys(navMenu).map(key => {
                if ( navMenu[key].limit == undefined ) {
                    limitedMenu = { ...limitedMenu, [key]: navMenu[key] }
                    return
                }
            })
            setLimitMenu(limitedMenu)
        }

        if ( permission.permission === "powerUser" ) {
            Object.keys(navMenu).map(key => {
                if (
                    key === "actual" ||
                    key === "distribution" ||
                    key === "profitManagement" ||
                    navMenu[key].limit == undefined
                ) {
                    limitedMenu = { ...limitedMenu, [key]: navMenu[key] }
                }
            })
            setLimitMenu(limitedMenu)
        }

        if ( permission.permission === "administrator" ) {
            Object.keys(navMenu).map(key => {
                if (
                    key === "actual" ||
                    key === "distribution" ||
                    key === "profitManagement" || 
                    key === "for developer" ||
                    key === "GoogleDirectorySync" ||
                    navMenu[key].limit == undefined
                ) {
                    limitedMenu = { ...limitedMenu, [key]: navMenu[key] }
                }
            })
            setLimitMenu(limitedMenu)
        }
    }, [permission])

    return (
        <>
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <LeftSidebar sidebarOpen={sidebarOpen} menu={limitMenu} />
        </>
    );
};
export default ResponsiveAppBar;

function Header ({ sidebarOpen, setSidebarOpen }:any) {
    const classes = useStyles();
    const { user, userTeamcode, permission, userHasDouble }:any = useAuthContext();

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [noticeAnchorEl, setNoticeAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [value, setValue] = useState(0);
    const [myTasks, setMyTasks] = useState<any>();
    const [uploadOpen, setUploadOpen] = useState(false)

    const headerRef = useRef(null)
    const noticeRef = useRef<HTMLElement>(null)

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    useEffect(() => {
        database.ref(`db_notice/${user.uid}`).on("value", (snapshot) => {
            if ( snapshot.val() == undefined ) return
            setMyTasks(snapshot.val())
        })
    }, [])

    const noticeHandleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        setNoticeAnchorEl(event.currentTarget);
        await NoticeDelete(user.uid)
    };
  
    const noticeHandleClose = () => {
        setNoticeAnchorEl(null);
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const popOpen = Boolean(anchorEl);
    const noticeOpen = Boolean(noticeAnchorEl);
    const id = noticeOpen ? 'simple-popover' : undefined;

    return (
        <>
        <AppBar position="fixed" className={classes.appbar}>
            <Container maxWidth={false} disableGutters>
                <Toolbar variant='dense' className={classes.toolbar} sx={{ overflow:"hidden" }} ref={headerRef} >
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        // onClick={() => setLeftsidebarOpen(!leftsidebarOpen)}
                        onClick={() => setSidebarOpen(!sidebarOpen)}
                        edge="start"
                        sx={{ marginRight: 5 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        // sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex' },
                            fontFamily: "'Poppins', Arial,Helvetica,sans-serif",
                        }}
                    >
                        TeamManagement
                    </Typography>
                    <SearchFromHeader headerRef={headerRef} />
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Tooltip title="ファイルアップロード" placement="bottom">
                            <IconButton
                                size="large"
                                color="inherit"
                                onClick={()=>setUploadOpen(true)}
                            >
                                <FileUploadRounded />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Tooltip title="リリースノート" placement="bottom">
                            <IconButton
                                size="large"
                                color="inherit"
                                onClick={() => window.open(process.env?.REACT_APP_HELPLINK, '_blank', 'noopener,noreferrer')}
                            >
                                <InfoRounded />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box ref={noticeRef} sx={{ display: { xs: 'none', md: 'flex' } }}>
                        <Tooltip title="新着通知" placement="bottom">
                            <IconButton
                                size="large"
                                color="inherit"
                                onClick={noticeHandleClick}
                            >
                                { myTasks != undefined
                                ?
                                <Badge
                                    variant="dot"
                                    color="error"
                                    overlap="circular"
                                >
                                    <Notifications />
                                </Badge>
                                :
                                <Badge>
                                    <Notifications />
                                </Badge>
                                }
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Popover
                        id={id}
                        open={noticeOpen}
                        anchorEl={noticeAnchorEl}
                        onClose={noticeHandleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                    >
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    variant="fullWidth"
                                    aria-label="full width tabs example"
                                    style={{ padding:0 }}
                                >
                                    <Tab label="タスク" {...a11yProps(0)} />
                                    <Tab label="他のユーザーのタスク" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0}>
                                <Tasks myTasks={myTasks} setMyTasks={setMyTasks} user={user}/>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <List disablePadding sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                    <ListItem alignItems="flex-start">
                                        <ListItemText
                                            secondary={
                                            <React.Fragment>
                                                <img src={NoTasks} alt="NoTasks" style={{ width:"70%", marginBottom:"32px", marginTop:"16px" }} /><br/>
                                                {"進行中のタスクはありません"}
                                            </React.Fragment>
                                            }
                                            style={{ textAlign:"center", marginBottom:"8px" }}
                                        />
                                    </ListItem>
                                </List>
                            </TabPanel>
                        </Box>
                    </Popover>
                    <Box style={{ paddingLeft:8 }}>
                        <Typography
                            aria-owns={popOpen ? 'mouse-over-popover' : undefined}
                            aria-haspopup="true"
                            onMouseEnter={handlePopoverOpen}
                            onMouseLeave={handlePopoverClose}
                        >
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar src={user.photoURL} style={{ height:32, width:32 }} />
                            </IconButton>
                        </Typography>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <div style={{ padding:"16px" }}>
                                <div style={{ display:"flex", justifyContent:"center" }}>
                                    <Avatar src={user.photoURL} style={{ margin:"auto", verticalAlign:"middle" }} />
                                    <div style={{ marginLeft:"16px" }}>
                                        <div style={{ display:"flex" }}>
                                            <Typography>{user.displayName}</Typography>
                                            { permission.permission === "administrator" && 
                                                <Tooltip title="管理者" placement='right'>
                                                    <VerifiedOutlined color="primary" style={{ fontSize:"18px", marginLeft:"4px", marginTop:"1.5px" }} />
                                                </Tooltip>
                                            }
                                            { permission.permission === "powerUser" && 
                                                <Tooltip title="経理部権限" placement='right'>
                                                    <Verified color="primary" style={{ fontSize:"18px", marginLeft:"4px", marginTop:"1.5px" }} />
                                                </Tooltip>
                                            }
                                            { permission.permission === "user" && 
                                                <Tooltip title="一般ユーザー" placement='right'>
                                                    <Verified color="action" style={{ fontSize:"18px", marginLeft:"4px", marginTop:"1.5px" }} />
                                                </Tooltip>
                                            }
                                        </div>
                                        <Typography variant="body2" color="text.secondary">
                                            {user.email}
                                        </Typography>
                                        <Profile />
                                    </div>
                                </div>
                            </div>
                            <Divider style={{ marginBottom:"8px" }} />
                            <DownloadButton name={"MP"} userTeamcode={userTeamcode} userHasDouble={userHasDouble} />
                            <DownloadButton name={"予定"} userTeamcode={userTeamcode} userHasDouble={userHasDouble} />
                            <DownloadButton name={"見込"} userTeamcode={userTeamcode} userHasDouble={userHasDouble} />
                            <Divider style={{ marginBottom:"8px" }} />
                            <MenuItem onClick={SignOut}>
                                <LogoutOutlined style={{ marginRight:"8px", opacity:"0.8" }} />
                                <Typography
                                    textAlign="center"
                                    style={{ fontSize:"14px" }}
                                >
                                    ログアウト
                                </Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
                { uploadOpen && <QuickUpload open={uploadOpen} setOpen={setUploadOpen} setNoticeAnchorEl={setNoticeAnchorEl} noticeRef={noticeRef} /> }
            </Container>
        </AppBar>
        </>
    )
}

function DownloadButton ({ name, userTeamcode, userHasDouble }:{
    name : "MP" | "見込" | "予定";
    userTeamcode: string;
    userHasDouble: Array<string>;
}){
    const [loading, setLoading] = useState(false)                   // チームの兼務をされていない場合
    const [buttonLoading, setButtonLoading] = useState(false)       // 複数のチームを兼務されている場合
    const [open, setOpen] = useState(false)

    const selection = () => {
        if ( userHasDouble.length > 0 ) {
            setOpen(true)
        } else {
            primaryHandler()
        }
    }

    const hasDoubleHandler = (teamcode:string) => {
        setButtonLoading(true)
        switch (name) {
            case "MP":
                TemplateDownloadMP(teamcode)
                .then(() => {
                    setButtonLoading(false)
                    closeTrigger()
                })
                .catch(() => false)
                break;
            case "予定":
                TemplateDownloadYotei(teamcode)
                .then(() => {
                    setButtonLoading(false)
                    closeTrigger()
                })
                .catch(() => false)
                break;
            case "見込":
                TemplateDownloadMikomi(teamcode)
                .then(() => {
                    setButtonLoading(false)
                    closeTrigger()
                })
                .catch(() => false)
                break;
        }
    }

    const primaryHandler = () => {
        setLoading(true)
        switch (name) {
            case "MP":
                TemplateDownloadMP(userTeamcode)
                .then(() => setLoading(false))
                .catch(() => false)
                break;
            case "予定":
                TemplateDownloadYotei(userTeamcode)
                .then(() => setLoading(false))
                .catch(() => false)
                break;
            case "見込":
                TemplateDownloadMikomi(userTeamcode)
                .then(() => setLoading(false))
                .catch(() => false)
                break;
        }
    }

    const err = () => {
        window.alert("データベースの読み込みに失敗したため、ダウンロードできませんでした。")
        return false
    }

    const closeTrigger = () => { setOpen(false) }

    return (
        <>
        <MenuItem
            onClick={()=>selection()}
            disabled={loading || userTeamcode === "" ? true : false}
        >
            { loading ?
            <div style={{ height:"24px", width:"24px", marginRight:"8px", textAlign:"center" }} >
                <CircularProgress style={{ height:"16px", width:"16px", verticalAlign:"middle" }} />
            </div>
            : <FileDownloadOutlined style={{ marginRight:"8px", opacity:"0.8" }} />
            }
            <Typography
                textAlign="center"
                style={{ fontSize:"14px" }}
            >
                {name}のテンプレート
            </Typography>
        </MenuItem>
        <DownloadSelection
            userTeamcode={userTeamcode}
            userHasDouble={userHasDouble}
            open={open}
            setOpen={setOpen}
            hasDoubleHandler={hasDoubleHandler}
            closeTrigger={closeTrigger}
            buttonLoading={buttonLoading}
        />
        </>
    )
}

function DownloadSelection ({ userTeamcode, userHasDouble, open, hasDoubleHandler, closeTrigger, buttonLoading }:any) {
    const [teamcode, setTeamcode] = useState("")
    
    return (
        <>
        <Dialog
            open={open}
            onClose={closeTrigger}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-decription"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                チームコードを選択してダウンロード
            </DialogTitle>
            <DialogContent>
                <Box sx={{ minWidth:120 }}>
                    <FormControl
                        variant="standard"
                        fullWidth
                        size="medium"
                        margin="normal"
                    >
                        <InputLabel id="select-label" shrink>チームコード</InputLabel>
                        <Select
                            labelId="select-label"
                            id="syurui-select"
                            onClick={(e)=>{
                                const target = e.target as HTMLElement;
                                const elementId:string = target.id;
                                setTeamcode(elementId)
                            }}
                            disabled={buttonLoading ? true :false}
                        >
                            <MenuItem id={userTeamcode} value={userTeamcode}>{userTeamcode}</MenuItem>
                            { userHasDouble.map((code:string) => (
                                <MenuItem id={code} value={code}>{code}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions
                style={{ padding:"16px" }}
            >
                <Button
                    color="inherit"
                    onClick={closeTrigger}
                >
                    キャンセル
                </Button>
                <LoadingButton
                    variant='contained'
                    onClick={() => hasDoubleHandler(teamcode)}
                    loading={buttonLoading}
                    disabled={teamcode === "" ? true : false}
                >
                    ダウンロード
                </LoadingButton>
            </DialogActions>
        </Dialog>
        </>
    )
}

function Profile() {
    const { userTeamcode }:any = useAuthContext();
    const [teams, setTeams] = useState<any>();

    useEffect(() => {
        ReadTeams()
        .then(result => setTeams(result))
        .catch(error => { throw error })
    }, [])

    const responseTeamcode = () => {
        try {
            // GWSのユーザー情報に登録が必要なパターン
            if ( userTeamcode === "" ) return ("ユーザー情報未設定")
        } catch {
            // UIDがJSONに登録されてないパターン
            return ("UID未登録")
        }

        try {
            // 正常なパターン
            return (`${userTeamcode} ${teams[userTeamcode].name}`)
        } catch {
            // GWSのユーザー情報に誤りがあるパターン
            return (`チームコードなし`)
        }
    }

    return (
        <Typography variant="body2" color="text.secondary">
            {responseTeamcode()}
        </Typography>
    )
}

function SearchFromHeader ({ headerRef }:any) {
    const [code, setCode] = useState<string>('');
    const [year, setYear] = useState('');
    const [month, setMonth] = useState('');
    const [syurui, setSyurui] = useState('');
    const [isVisible, setIsVisible] = useState(false)

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [inputErrorYear, setInputErrorYear] = useState(false);
    const [inputErrorMonth, setInputErrorMonth] = useState(false);

    const inputRefYear = useRef<HTMLInputElement>(null);
    const inputRefMonth = useRef<HTMLInputElement>(null);
    const teamcodeRef = useRef<HTMLInputElement>(null);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const toggleVisibility = () => {
        window.scrollY > 117
        ? setIsVisible(true)
        : setIsVisible(false)
    }

    useEffect(() => {
        window.addEventListener("scroll", toggleVisibility)
        return () => window.removeEventListener("scroll", toggleVisibility)
    })

    const handleOpenSearch = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const searchHandleClose = () => {
        setAnchorEl(null);
        setInputErrorMonth(false)
        setInputErrorYear(false)
        setSyurui("")
    };

    const handleChange = (event: SelectChangeEvent) => {
        setSyurui(event.target.value as string)
    }
    
    const formChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        switch ( e.target.name ) {
            case 'searchCode': {
                setCode(e.target.value)
                break;
              }
            case 'searchSyurui': {
                setSyurui(e.target.value)
                break;
            }
            case 'searchYear': {
                if ( inputRefYear.current ) {
                    const ref = inputRefYear.current;
                    ref.setCustomValidity('')
                    if ( !ref.validity.valid ) {
                        ref.setCustomValidity('数字を入力してください。')
                        setInputErrorYear(true)
                    } else {
                        setInputErrorYear(false)
                        setYear(e.target.value)
                    }
                }
                break;
            }
            case 'searchMonth': {
                if ( inputRefMonth.current ) {
                    const ref = inputRefMonth.current;
                    ref.setCustomValidity('')
                    if ( !ref.validity.valid ) {
                        ref.setCustomValidity('数字を入力してください。')
                        setInputErrorMonth(true)
                    } else {
                        setInputErrorMonth(false)
                        setMonth(e.target.value)
                    }
                }
                break;
            }
        }
    }

    const headerSearchEvent = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        searchHandleClose()
    }

    return (
        <>
        <Slide direction="up" in={isVisible} container={headerRef.current}>
            <Button
                color="inherit"
                size='small'
                variant='outlined'
                sx={{ flexGrow: 1, display: { xs:'none', md:'flex' }, borderRadius:"20px" }}
                onClick={handleOpenSearch}
            >
                <SearchOutlined style={{ marginRight:"8px" }} />
                    条件を指定して検索
                <ExpandMoreRounded />
            </Button>
        </Slide>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={searchHandleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <div style={{ padding:"24px", width:"480px" }}>
            <form onSubmit={headerSearchEvent}>
                <div style={{ marginBottom:"24px" }}>
                    <TextField
                        id="outlined-search"
                        variant="outlined"
                        name="searchCode"
                        label="チームコード"
                        type="text"
                        size="small"
                        inputRef={teamcodeRef}
                        onChange={formChange}
                        InputLabelProps={{ shrink: true }}
                    />
                </div>
                <div style={{ marginBottom:"24px" }}>
                    <TextField
                        id="outlined-search"
                        name="searchYear"
                        label="年度"
                        type="text"
                        size="small"
                        error={inputErrorYear}
                        inputProps={{ maxLength:4, pattern:"^[0-9]+$" }}
                        inputRef={inputRefYear}
                        helperText={inputRefYear?.current?.validationMessage}
                        onChange={formChange}
                        InputLabelProps={{ shrink: true }}
                    />
                </div>
                <div style={{ marginBottom:"24px" }}>
                    <TextField
                        id="outlined-search"
                        name="searchMonth"
                        label="月度"
                        type="text"
                        size="small"
                        error={inputErrorMonth}
                        inputProps={{ maxLength:2, pattern:"^[0-9]+$" }}
                        inputRef={inputRefMonth}
                        helperText={inputRefMonth?.current?.validationMessage}
                        onChange={formChange}
                        InputLabelProps={{ shrink: true }}
                    />
                </div>
                <div style={{ marginBottom:"24px" }}>
                    <FormControl variant="outlined" fullWidth size="small" style={{ textAlign:"left" }}>
                        <InputLabel id="select-label" shrink>種類</InputLabel>
                        <Select
                            labelId="select-label"
                            id="syurui-select"
                            value={syurui}
                            onChange={handleChange}
                            input={
                                <OutlinedInput
                                    notched
                                    label="種類"
                                />
                            }
                        >
                            <MenuItem value="MP">MP</MenuItem>
                            <MenuItem value="予定">予定</MenuItem>
                            <MenuItem value="見込">見込</MenuItem>
                            <MenuItem value="実績">実績</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <Button
                    fullWidth
                    disabled={ inputErrorYear || inputErrorMonth }
                    variant="contained"
                    type="submit"
                    style={{ marginTop:"16px" }}
                >
                    検索
                </Button>
            </form>
            </div>
        </Popover>
        </>
    )
}

function Tasks({ myTasks, setMyTasks, user }:any) {
    const { state, } = useContext(Store);

    // db_notice/{uid} にデータがないならemtystate返す
    const date = new Date();
    date.setTime(date.getTime() + 1000*60*60*9)
    const nowDate = String(date.getUTCFullYear()) + "/" + String(Number(date.getUTCMonth()+1)) + "/" + String(date.getUTCDate())
 
    const deleteTaskTrigger = async (e:React.MouseEvent<HTMLElement, MouseEvent>) => {
        const targetId:string = e.currentTarget.id;
        NoticeDelete(user.uid, targetId)
        const tasks:any = await NoticeRead(user.uid)
        setMyTasks(tasks)
    }

    const progressMyTasks = ():any => (
        Object.keys(state).map((key:string) => (
            state[key].status === "loading"
            ? 
            <ListItem alignItems="flex-start">
                <ListItemAvatar >
                    <CircularProgressWithLabel value={state[key].count/state[key].maxCount*100} />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <React.Fragment>
                            <span style={{ fontSize:"14px" }}>
                                {"タスクは進行中です..."}
                            </span>
                        </React.Fragment>
                    }
                    secondary={
                        <React.Fragment>
                            <span style={{ fontSize:"14px" }}>
                                {`${state[key].name}`}
                            </span>
                        </React.Fragment>
                    }
                    style={{ margin:"auto"}}
                />
            </ListItem>
            :
            <ListItem alignItems="flex-start">
                <ListItemAvatar >
                    <CheckCircleOutline color='success' />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <React.Fragment>
                            <span style={{ fontSize:"14px" }}>
                                {"完了しました"}
                            </span>
                        </React.Fragment>
                    }
                    secondary={
                        <React.Fragment>
                            <span style={{ fontSize:"14px" }}>
                                {`${state[key].name}`}
                            </span>
                        </React.Fragment>
                    }
                    style={{ margin:"auto" }}
                />
            </ListItem>
        ))
    )

    if ( myTasks == undefined || null ) {
        return (
            <List disablePadding sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                { Object.keys(state).length === 0
                ? 
                <ListItem alignItems="flex-start">
                    <ListItemText
                        secondary={
                        <React.Fragment>
                            <img src={NoTasks} alt="NoTasks" style={{ width:"70%", marginBottom:"32px", marginTop:"16px" }} /><br/>
                            {"進行中のタスクはありません"}
                        </React.Fragment>
                        }
                        style={{ textAlign:"center", marginBottom:"8px" }}
                    />
                </ListItem>
                :
                progressMyTasks()
                }
            </List>
        )
    }

    return (
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            { Object.keys(state).length > 0 ? progressMyTasks() : <></> }
            <TransitionGroup>
                { Object.keys(myTasks).reverse().map((key:string) => (
                <Collapse key={key}>
                    <NotificationsListItem
                        id={key}
                        className="notifications-listitem"
                        alignItems="flex-start"
                        style={{  paddingBottom:"24px", marginRight:"24px" }}
                    >
                        <ListItemAvatar
                            style={{ minWidth:"40px" }}
                        >
                            { myTasks[key].status === "success"
                            ? <CheckCircleOutline color='success' />
                            : <Error color="error" />
                            }
                        </ListItemAvatar>
                        <div style={{ display:"flex", flexFlow:"column" }}>
                            <ListItemText
                                primary={
                                    <React.Fragment>
                                        <span style={{ fontSize:"14px" }}>
                                            {`${myTasks[key].message.split(`\n`)[0]}`}
                                        </span>
                                    </React.Fragment>
                                }
                                style={{ margin:"auto" }}
                                secondary={
                                    <React.Fragment>
                                        <span style={{ fontSize:"14px" }}>
                                            {`${myTasks[key].message.split(`\n`)[1]}`}
                                        </span>
                                    </React.Fragment>
                                }
                            />
                            <span
                                style={{
                                    fontSize:"12px",
                                    color:"rgba(0, 0, 0, 0.6)",
                                    position:"absolute",
                                    right:"16px",
                                    bottom:0
                                }}
                            >
                                { nowDate === String(dayjs(myTasks[key].date).format("YYYY/M/D"))
                                ? `${dayjs(myTasks[key].date).format("HH:mm")}`
                                : `${dayjs(myTasks[key].date).format("YYYY/M/D")}`
                                }
                            </span>
                        </div>
                        <ListItemButton id={key} className="notifications-deletebutton" onClick={(e)=>deleteTaskTrigger(e)} style={{ backgroundColor: 'transparent' }}>
                            <CloseRounded color='action' />
                        </ListItemButton>
                    </NotificationsListItem>
                </Collapse>
                ))}
            </TransitionGroup>
        </List>
    )
}

function CircularProgressWithLabel(
    props: CircularProgressProps & { value: number },
) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
            sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            }}
        >
            <Typography
                variant="caption"
                component="div"
                color="primaryNavItem.secondary"
            >{`${Math.round(props.value)}%`}</Typography>
        </Box>
        </Box>
    );
}

function LeftSidebar ({ sidebarOpen, menu }:any) {
    const theme:any = useTheme();
    const classes = useStyles();
    const location = useLocation();

    const [open, setOpen] = React.useState(false);
    const [selectMenu, setSelectMenu] = React.useState<string>('');
    const [accordion, setAccordion] = React.useState<any>({...menu});

    useEffect(() => {
        let o:any = {}
        let navList:Array<string> = Object.keys(menu)
        navList.map(key => {
            if ( menu[key].items === undefined ) {
                o = { ...o, [menu[key][key].path]: key }
                return
            }
            menu[key].items.map((item:any) => {
                o = { ...o, [menu[key][item].path]: item }
            })
        })
        setSelectMenu(o[location.pathname])

        // If root menu.
        if ( navList.includes(o[location.pathname]) ) return

        // 子供のメニューが開かれている
        navList.map(key => {
            if ( menu[key].items == undefined ) return
            menu[key].items.map((item:string) => {
                if ( location.pathname === menu[key][item].path ) {
                    accordion[key].open = true
                    setAccordion({ ...accordion })
                }
            })
        })
    }, [])

    const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
        let targetMenu:string = String(event.currentTarget.id);

        // If chilld menu.
        if ( !Object.keys(menu).includes(targetMenu) ) {
            setSelectMenu(targetMenu)
            return
        }

        // If parent without child.
        if ( menu[targetMenu].items === undefined ) {
            setSelectMenu(targetMenu)
            return
        }

        // If parent has child.
        if ( accordion[targetMenu].open === false ) {
            accordion[targetMenu].open = true
            setAccordion({ ...accordion })
        } else {
            accordion[targetMenu].open = false
            setAccordion({ ...accordion })
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <StyledDrawer
            variant="permanent"
            open={sidebarOpen}
            PaperProps={{
                sx: {
                    backgroundColor: "#FAFBFC",
                }
            }}
        >
            <DrawerHeader>
                <IconButton onClick={() => setOpen(!open)}>
                    {theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
                </IconButton>
            </DrawerHeader>
            <List>
            {Object.keys(menu).map(navKey => (
                menu[navKey].items !== undefined ?
                <>
                    <Tooltip
                        title={menu[navKey].name}
                        placement="right-end"
                    >
                        <ListItemButton
                            id={navKey}
                            key={navKey}
                            sx={{
                                minHeight: 36,
                                maxHeight: 36,
                                justifyContent: sidebarOpen ? 'initial' : 'center',
                                px: 2.5,
                                borderRadius: 10,
                            }}
                            onClick={handleClickMenu}
                            style={ selectMenu === navKey ? { backgroundColor: '#d2e3fc' } : {  } }
                        >
                            <Box
                                className={ accordion[navKey].open === false
                                    ? classes.closeMenu
                                    : classes.openMenu
                                }
                            >
                                ▶
                            </Box>
                            <Box
                                sx={{
                                    display:'flex',
                                    flexDirection:'row',
                                    alignItems:'center',
                                    width:'100%'
                                }}
                            >
                                <ListItemIcon
                                    sx={ selectMenu === navKey ? {
                                        minWidth: 0,
                                        mr: sidebarOpen ? 2.5 : 'auto',
                                        color: '#1a73e8'
                                    } : {  
                                        minWidth: 0,
                                        mr: sidebarOpen ? 2.5 : 'auto',
                                    }}
                                >
                                    { IconSelection(menu[navKey].name, IconStyle) }
                                </ListItemIcon>
                                <ListItemText
                                    primary={menu[navKey].name}
                                    sx={{ opacity: sidebarOpen ? 1 : 0 }}
                                    primaryTypographyProps={ selectMenu === navKey ? 
                                        {
                                            fontFamily: "Roboto",
                                            fontWeight: 'bold',
                                            letterSpacing: "0.5px",
                                            color: '#1a73e8',
                                            fontSize: 14
                                        } : {
                                            fontFamily: "Roboto",
                                            fontWeight: '400',
                                            letterSpacing: "0.5px",
                                            fontSize: 14 
                                        } 
                                    }
                                />
                            </Box>
                        </ListItemButton>
                    </Tooltip>
                    <div
                        className={accordion[navKey].open === false
                            ? classes.accordion
                            : classes.noneAccordion
                        }
                    >
                    { menu[navKey].items.map((item:any) => (
                        <Link
                            to={menu[navKey][item].path}
                            style={{ textDecoration: 'none', color: 'black' }}
                        >
                            <Tooltip
                                title={menu[navKey][item].name}
                                placement="right-end"
                            >
                                <ListItemButton
                                    id={item}
                                    key={item}
                                    sx={{
                                        minHeight: 36,
                                        maxHeight: 36,
                                        justifyContent: sidebarOpen ? 'initial' : 'center',
                                        px: 2.5,
                                        borderRadius: 10,
                                    }}
                                    onClick={handleClickMenu}
                                    style={ selectMenu === item ? { backgroundColor: '#d2e3fc' } : {  } }
                                >
                                    <ListItemIcon
                                        sx={ selectMenu === item ?
                                            {
                                                minWidth: 0,
                                                mr: sidebarOpen ? 2.5 : 'auto',
                                                justifyContent: 'center',
                                                color: '#1a73e8'
                                            } : {  
                                                minWidth: 0,
                                                mr: sidebarOpen ? 2.5 : 'auto',
                                                justifyContent: 'center',
                                            }
                                        }
                                    >
                                        { IconSelection(menu[navKey][item].name, IconStyle) }
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={menu[navKey][item].name}
                                        sx={{ opacity: sidebarOpen ? 1 : 0 }}
                                        primaryTypographyProps={ selectMenu === item ?
                                            {
                                                fontFamily: "Roboto",
                                                fontWeight: 'bold',
                                                letterSpacing: "0.5px",
                                                color: '#1a73e8',
                                                fontSize: 14
                                            } : {
                                                fontFamily: "Roboto",
                                                fontWeight: '400',
                                                letterSpacing: "0.5px",
                                                fontSize: 14
                                            }
                                        }
                                    />
                                </ListItemButton>
                            </Tooltip>
                        </Link>
                    ))}
                    </div>
                </>
                :
                <Link
                    to={menu[navKey][navKey].path} 
                    style={{ textDecoration: 'none', color: 'black' }}
                >
                    <Tooltip
                        title={menu[navKey][navKey].name}
                        placement="right-end"
                    >
                    <ListItemButton
                        id={navKey}
                        key={navKey}
                        sx={{
                            minHeight: 36,
                            maxHeight: 36,
                            justifyContent: sidebarOpen ? 'initial' : 'center',
                            px: 2.5,
                            borderRadius: 10,
                        }}
                        onClick={handleClickMenu}
                        style={ selectMenu === navKey ? { backgroundColor: '#d2e3fc' } : {  } }
                    >
                        <ListItemIcon
                            sx={ selectMenu === navKey ? {
                                minWidth: 0,
                                mr: sidebarOpen ? 2.5 : 'auto',
                                justifyContent: 'center',
                                color: '#1a73e8'
                            } : {  
                                minWidth: 0,
                                mr: sidebarOpen ? 2.5 : 'auto',
                                justifyContent: 'center',
                            }}
                        >
                            { IconSelection(menu[navKey][navKey].name, IconStyle) }
                        </ListItemIcon>
                        <ListItemText
                            primary={menu[navKey][navKey].name}
                            sx={{ opacity: sidebarOpen ? 1 : 0 }}
                            primaryTypographyProps={ selectMenu === navKey ?
                                {
                                    fontFamily: "Roboto",
                                    fontWeight: 'bold',
                                    letterSpacing: "0.5px",
                                    color: '#1a73e8',
                                    fontSize: 14
                                } : {
                                    fontFamily: "Roboto",
                                    fontWeight: '400',
                                    letterSpacing: "0.5px",
                                    fontSize: 14
                                }
                            }
                        />
                    </ListItemButton>
                    </Tooltip>
                </Link>
            ))}
            </List>
        </StyledDrawer>
    </Box>
    )

}

function IconSelection (key:string, style:any) {
    switch(key) {
        case('ダッシュボード') : { return (<InsightsOutlined style={style} />) }
        case('採算表照会') : { return (<ViewListOutlined style={style} />) }
        case('按分') : { return (<Percent style={style} />) }
        case('マスタ管理') : { return (<Storage style={style} />) }
        case('for developer') : { return (<VerifiedOutlined style={style} />) }
        case('MP照会') : { return (<SearchOutlined style={style} />) }
        case('予定照会') : { return (<SearchOutlined style={style} />) }
        case('見込／概算照会') : { return (<SearchOutlined style={style} />) }
        case('予実採算表') : { return (<SearchOutlined style={style} />) }
        case('アップロード') : { return (<FileUploadOutlined style={style} />) }
        case('実績管理') : { return (<CalculateOutlined style={style} />) }
        case('部門集計表') : { return (<SearchOutlined style={style} />) }
        case('実績推移表') : { return (<SearchOutlined style={style} />) }
        case('按分処理') : { return (<Percent style={style} />) }
        case('按分結果照会') : { return (<SearchOutlined style={style} />) }
        case('按分パターン設定') : { return (<EditRounded style={style} />) }
        case('按分パターン照会') : { return (<SearchOutlined style={style} />) }
        case('チームマスタ') : { return (<GroupsOutlined style={style} />) }
        case('採算表フォーマット') : { return (<FormatListNumberedRounded style={style} />) }
        case('採算科目マスタ') : { return (<Storage style={style} />) }
        case('勘定科目マスタ') : { return (<Storage style={style} />) }
        case('按分マスタ') : { return (<Storage style={style} />) }
        case('確定処理管理') : { return (<LockOutlined style={style} />) }
        case('GoogleDirectorySync') : { return (<Google style={style} />) }
        case('採算表管理') : { return (<SettingsOutlined style={style} />) }
        case('新年度作成') : { return (<CreateNewFolderOutlined style={style} />) }
        case('提出状況管理') : { return (<PublishedWithChangesOutlined style={style} />) }
    }
}

const SignOut = () => {
    firebase.auth().signOut()
    .catch(error => console.log(`Signout error. ${error}`));
}

function QuickUpload ({ open, setOpen, setNoticeAnchorEl, noticeRef }:any) {
    const { user }:any = useAuthContext();
    const { state, dispatch } = useContext(Store);

    const [fileName, setFileName] = useState<string>('')
    const [uploadFile, setUploadFile] = useState<File>()
    const [error, setError] = useState(true)

    const fileInput = useRef<HTMLInputElement>(null)

    const closeTrigger = () => { setOpen(!open) }
    
    const uploadStatus = () => (
        <div style={{ display:"flex", justifyContent:"flex-end", verticalAlign:"middle" }}>
        { fileName !== "" ?
            <>
            { error
            ? <WarningRounded color='error' style={{ marginRight:"auto", marginTop:"auto", marginBottom:"auto" }} />
            : <Check style={{ marginRight:"auto", marginTop:"auto", marginBottom:"auto", color:"rgba(0, 0, 0, .5)" }} />
            }
                <div
                    style={{
                        marginRight:"auto",
                        marginTop:"auto",
                        marginBottom:"auto",
                        maxWidth:"70%",
                        fontSize:"14px",
                        flex:1,
                        display:"flex",
                        flexFlow:"column"
                    }}
                >
                    <Typography color={error ? "error" : "inerit"} style={{ fontSize:"14px" }}>{fileName}</Typography>
                    { error && 
                    <Typography color="error" style={{ fontSize:"12px", paddingTop:"8px" }}>
                        サポートされていないファイル形式です。アップローダーでサポートされているのは XLSX のみです
                    </Typography>
                    }
                </div>

            <Button
                variant="text"
                style={{ marginLeft:"auto" }}
                onClick={fileOpenTrigger}
            >
                置き換える
            </Button>
            <form style={{ display: 'none' }}>
                <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ref={fileInput}
                    onChange={changeImportFile}
                />
            </form>
            </>
        :
            <>
            <div
                style={{
                    marginRight:"auto",
                    marginTop:"auto",
                    marginBottom:"auto",
                    fontSize:"14px"
                }}
            >
                ファイルを選択してください
            </div>
            <Button
                variant="text"
                style={{ marginLeft:"auto" }}
                onClick={fileOpenTrigger}
            >
                参照
            </Button>
            <form style={{ display: 'none' }}>
                <input
                    type="file"
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ref={fileInput}
                    onChange={changeImportFile}
                />
            </form>
            </>
        }
        </div>
    )

    const changeImportFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();

        setFileName(e.target.files![0].name)
        if ( fileInput.current?.files![0].type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ) {
            setError(true)
            return
        }
        setError(false)
        setUploadFile(fileInput.current?.files![0])
        e.target.value = "";
    }
    
    const fileOpenTrigger = () => {
        if (fileInput.current) {
            fileInput.current.click()
        }
    }

    const uploadClickTrigger = (e: React.MouseEvent<HTMLElement>) => {
        if ( uploadFile == undefined ) return
        setNoticeAnchorEl(noticeRef.current)
        GlobalUploader(
            uploadFile,
            user,
            { state, dispatch },
        )
        .catch(error => console.log(error))
        closeTrigger()
    }

    return (
        <>
        <Dialog
            open={open}
            onClose={closeTrigger}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-decription"
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>
                採算表をアップロード
            </DialogTitle>
            <DialogContent>
                <div
                    style={{
                        border: "dashed 1px",
                        borderColor: "rgba(0, 0, 0, .2)",
                        borderRadius:"10px",
                        padding:"24px",
                        marginTop:"8px",
                    }}
                >
                    {uploadStatus()}
                </div>
            </DialogContent>
            <DialogActions
                style={{ padding:"16px" }}
            >
                <Button
                    color="inherit"
                    onClick={closeTrigger}
                >
                    キャンセル
                </Button>
                <Button
                    variant='contained'
                    onClick={(e) => uploadClickTrigger(e)}
                    disabled={ error ? true : false }
                    autoFocus
                >
                    アップロード
                </Button>
            </DialogActions>
        </Dialog>
        </>
    )
}